import React from "react";
import { Header } from "./components/Header";
import {Home} from "./pages/index";
import {AllRoutes} from "./routes/AllRoutes";
import { Container, colors } from "@mui/material";

export function App() {
  return (
    <>
      <div className="HomePageHeroGradient">
        <Header></Header>
        <Container sx={{border: colors.brown}}>
          <Home/>
        </Container>
        <AllRoutes/>
      </div>
    </>
  );
}
