import h from "../css/home.css";
import Box from "@mui/material/Box";

export function Home() {
    return(
        <>
                <Box sx = {{display: 'flex', justifyContent: "flext-start", flexDirection: 'row',  flexWrap: 'wrap'}}>
        <Box
          sx={{ display: 'flex', justifyContent: "flext-start", flexDirection: 'row',  flexWrap: 'wrap'}}>
          <div className="blob">
            <svg
              viewBox="0 0 500 500"
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              id="blobSvg"
            >
              <defs>
                <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                  <stop
                    offset="0%"
                    style={{ stopColor: "rgb(254, 0, 242)" }}
                  ></stop>
                  <stop
                    offset="100%"
                    style={{ stopColor: "rgb(251, 35, 66)" }}
                  ></stop>
                </linearGradient>
              </defs>
              <path fill="url(#gradient)">
                <animate
                  attributeName="d"
                  dur="15000ms"
                  repeatCount="indefinite"
                  values="M405.5,333Q346,416,250.5,415Q155,414,82,332Q9,250,82.5,169Q156,88,262.5,66.5Q369,45,417,147.5Q465,250,405.5,333Z;
    
    M403.5,329Q341,408,264,384Q187,360,106,305Q25,250,78.5,148Q132,46,236.5,69.5Q341,93,403.5,171.5Q466,250,403.5,329Z;
    
    M376,321.5Q333,393,244.5,402.5Q156,412,98,331Q40,250,108,186Q176,122,251.5,119.5Q327,117,373,183.5Q419,250,376,321.5Z;
    
    M367,331.5Q344,413,257,401Q170,389,114,319.5Q58,250,104.5,164.5Q151,79,242.5,92Q334,105,362,177.5Q390,250,367,331.5Z;
    
    M381.5,340Q354,430,246.5,436Q139,442,108.5,346Q78,250,122,177Q166,104,265,78.5Q364,53,386.5,151.5Q409,250,381.5,340Z;
    
    M405.5,333Q346,416,250.5,415Q155,414,82,332Q9,250,82.5,169Q156,88,262.5,66.5Q369,45,417,147.5Q465,250,405.5,333Z;
    "
                ></animate>
              </path>
            </svg>
          </div>

          <div className="blob">
            <svg
              viewBox="0 0 500 500"
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              id="blobSvg"
            >
              <defs>
                <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                  <stop
                    offset="0%"
                    style={{ stopColor: "rgb(254, 0, 242)" }}
                  ></stop>
                  <stop
                    offset="100%"
                    style={{ stopColor: "rgb(251, 35, 66)" }}
                  ></stop>
                </linearGradient>
              </defs>
              <path fill="url(#gradient)">
                <animate
                  attributeName="d"
                  dur="15000ms"
                  repeatCount="indefinite"
                  values="M405.5,333Q346,416,250.5,415Q155,414,82,332Q9,250,82.5,169Q156,88,262.5,66.5Q369,45,417,147.5Q465,250,405.5,333Z;
    
    M403.5,329Q341,408,264,384Q187,360,106,305Q25,250,78.5,148Q132,46,236.5,69.5Q341,93,403.5,171.5Q466,250,403.5,329Z;
    
    M376,321.5Q333,393,244.5,402.5Q156,412,98,331Q40,250,108,186Q176,122,251.5,119.5Q327,117,373,183.5Q419,250,376,321.5Z;
    
    M367,331.5Q344,413,257,401Q170,389,114,319.5Q58,250,104.5,164.5Q151,79,242.5,92Q334,105,362,177.5Q390,250,367,331.5Z;
    
    M381.5,340Q354,430,246.5,436Q139,442,108.5,346Q78,250,122,177Q166,104,265,78.5Q364,53,386.5,151.5Q409,250,381.5,340Z;
    
    M405.5,333Q346,416,250.5,415Q155,414,82,332Q9,250,82.5,169Q156,88,262.5,66.5Q369,45,417,147.5Q465,250,405.5,333Z;
    "
                ></animate>
              </path>
            </svg>
          </div>                       
        </Box>

    </Box>
        </>
    );
}