import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import {NavLink} from "react-router-dom";


//const pages = ['About Us', 'Our Services', 'Industries', 'Products', 'Insights', 'Contact Us'];

export function Header() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  
  const [servicesAnchorEl, setServicesAnchorEl] = React.useState(null);
  const openServices = Boolean(servicesAnchorEl);

  const[industriesAnchorEl, setIndustriesAnchorEl] = React.useState(null);
  const openIndustries = Boolean(industriesAnchorEl);


  const handleClickServices = (event) => {
    setServicesAnchorEl(event.currentTarget);
  };

  const handleCloseServices = (event) => {
    console.log('closeSrfNav : ' + event.currentTarget);
    setServicesAnchorEl(null);
  };

  const handleOpenIndustriesMenu = (event) => {
    setIndustriesAnchorEl(event.currentTarget);
  }

  const handleCloseIndustriesMenu = (event) => {
    setIndustriesAnchorEl(null);
  }


  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = (event) => {
    console.log('closeNav : ' + event.currentTarget);
    setAnchorElNav(null);
  };


  return (
    <AppBar  position="sticky"  style={{ 
        background: 'transparent', 
        boxShadow: 'none', 
        }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters sx={{ justifyContent: "space-between" }}>

        <Typography
            variant="h5"
            noWrap
            component="a"
            href="#app-bar-with-responsive-menu"
            sx={{
              mr: 2,
              display: { xs: 'flex'},
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            LOGO
          </Typography>

          <Box sx={{justifyContent: "flex-end", flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="menur"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' }             
              }}
              PaperProps={{sx: {width: '500px', minWidth: '350px'}}}
            >

            <MenuItem key='menu-header'>
            <Box sx={{
                    height: '30px',
                    width: '100%', minWidth: '350px'}}>
                        <Typography textAlign="left">LOGO</Typography>
                        
                   </Box> 
            </MenuItem>

            <MenuItem key='About Us' onClick={handleCloseNavMenu} sx={{padding: '5px' }}> 
                  <Box sx={{ height: '40px', width: '100%',backgroundColor: 'grey.100', borderRadius: '2px'}}>
                        <Typography textAlign="left" sx={{padding: '8px', color: '#3F4B66'}}>About Us</Typography>
                   </Box> 
            </MenuItem>                            

            <MenuItem key='Our Services' onClick={handleCloseNavMenu} sx={{padding: '5px' }}> 
                  <Box sx={{ height: '40px', width: '100%',backgroundColor: 'grey.100', borderRadius: '2px'}}>
                        <Typography textAlign="left" sx={{padding: '8px', color: '#3F4B66'}}>Our Services</Typography>
                   </Box> 
            </MenuItem>   

            <MenuItem key='Industries' onClick={handleCloseNavMenu} sx={{padding: '5px' }}> 
                  <Box sx={{ height: '40px', width: '100%',backgroundColor: 'grey.100', borderRadius: '2px'}}>
                        <Typography textAlign="left" sx={{padding: '8px', color: '#3F4B66'}}>Industries</Typography>
                   </Box> 
            </MenuItem>   

            <MenuItem key='Products' onClick={handleCloseNavMenu} sx={{padding: '5px' }}> 
                  <Box sx={{ height: '40px', width: '100%',backgroundColor: 'grey.100', borderRadius: '2px'}}>
                        <Typography textAlign="left" sx={{padding: '8px', color: '#3F4B66'}}>Products</Typography>
                   </Box> 
            </MenuItem>   

            <MenuItem key='Contact Us' onClick={handleCloseNavMenu} sx={{padding: '5px' }}> 
                  <Box sx={{ height: '40px', width: '100%',backgroundColor: 'grey.100', borderRadius: '2px'}}>
                        <Typography textAlign="left" sx={{padding: '8px', color: '#3F4B66'}}>Contact Us</Typography>
                   </Box> 
            </MenuItem>   


            </Menu>
          </Box>



          <Box sx={{ justifyContent: "flex-end", flexGrow: 1, display: { xs: 'none', md: 'flex' }, marginTop: 3, marginRight: 10}}>
              
              <div>
                  <Button key='About Us' onClick={handleCloseNavMenu} sx={{ my: 2, color: 'white', display: 'block' }}>
                    About Us
                  </Button>                 
              </div>
          
              <div>
                <Button key='Our Services' onMouseOver={handleClickServices} sx={{ my: 2, color: 'white', display: 'block' }}>
                  Our Services
                </Button>
                <Menu
                      id="our-services-menu"
                      anchorEl={servicesAnchorEl}
                      open={openServices}
                      onClose={handleCloseServices}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                    >
                    <MenuItem onClick={handleCloseServices}>GenAI</MenuItem>
                    <MenuItem onClick={handleCloseServices}>
                      <nav>
                        <NavLink to="/mobile-development"> Mobile Development</NavLink>                    
                      </nav>
                    </MenuItem>
                    <MenuItem onClick={handleCloseServices}>Web Development</MenuItem>
                    <MenuItem onClick={handleCloseServices}>Backend Development</MenuItem>
                    <MenuItem onClick={handleCloseServices}>UX Design</MenuItem>                    
                  </Menu>
              </div>


              <div>
                <Button key='Industries' onMouseOver={handleOpenIndustriesMenu} sx={{ my: 2, color: 'white', display: 'block' }}>
                  Industries
                </Button>
                <Menu
                        id="industries-menu"
                        anchorEl={industriesAnchorEl}
                        open={openIndustries}
                        onClose={handleCloseIndustriesMenu}
                        MenuListProps={{
                          'aria-labelledby': 'basic-button',
                        }}
                      >
                      <MenuItem onClick={handleCloseIndustriesMenu}>HealthCare</MenuItem>
                      <MenuItem onClick={handleCloseIndustriesMenu}>E-Commerce</MenuItem>
                      <MenuItem onClick={handleCloseIndustriesMenu}>BFSI</MenuItem>
                      <MenuItem onClick={handleCloseIndustriesMenu}>Tourism</MenuItem>
                      <MenuItem onClick={handleCloseIndustriesMenu}>Mobility</MenuItem>

                    </Menu>
              </div>

              <Button key='Products' onClick={handleCloseNavMenu} sx={{ my: 2, color: 'white', display: 'block' }}>
                Products
              </Button>

              <Button key='Contact Us' onClick={handleCloseNavMenu} sx={{ my: 2, color: 'white', display: 'block' }}>
                Contact Us
              </Button>

             
           
          </Box>
        
               
        </Toolbar>
      </Container>
    </AppBar>
  );
}