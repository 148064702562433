import {Routes, Route} from "react-router-dom";
import {Home, MobileDevelopment, PageNotFound} from "../pages/index";


export function AllRoutes() {

    return(
        <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="mobile-development" element={<MobileDevelopment/>}/>                
                <Route path="*" element={<PageNotFound/>}></Route>
        </Routes>
    );

}